import axios from "axios";

const API_URL =  process.env.REACT_APP_SW_SERVICE_ADMIN_URL;
const appKey = process.env.REACT_APP_SW_SERVICE_KEY
const login = (username, password) => {
  console.log(appKey)
  return axios
    .post(API_URL + "/auth/login", {
      username,
      password,
    }, {
      headers: {'Content-Type': 'application/json', 'api-key':appKey}
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token",response.data.accessToken);
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
};
