import {Box, useTheme, Button, IconButton, Stack} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import DownloadCSV from "../../helpers/DownloadCSV";
import { useEffect, useReducer, useState, ChangeEvent } from "react";
import { randomInt, randomUserName, random } from '@mui/x-data-grid-generator';
import EventBus from "../../common/EventBus";
import styles from './id.css'
import { useNavigate } from "react-router-dom";
import DownloadOutlined from "@mui/icons-material/DownloadOutlined";
import UploadOutlined from "@mui/icons-material/UploadOutlined";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import { parse } from "csv-parse/browser/esm/sync";
import { useRef } from 'react';

const swServiceUrls = process.env.REACT_APP_SW_SERVICE_URL
const token = process.env.REACT_APP_SW_SERVICE_KEY

const Popup = ({ onClose, onSave, tableData }) => {
    const [inputValue, setInputValue] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [newSave, setNewSave] = useState(true);
    const [errors, setErrors] = useState({key:false,message:''});
    const handleSave = () => {
        // Call the onSave callback with input value and checkbox state
        onSave(inputValue, isChecked);
        // Close the popup
        onClose();
    };

    return (
        <div className={styles.overlay}>
            <div className={styles.popup}>
            <h2>Create new key</h2>
            <label>
                Key:
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                        if (e.target.value.match(/[^A-Za-z\d]/) === null) {
                            if(e.target.value.length<9) {
                                setInputValue(e.target.value)
                                if(e.target.value.length===8) {
                                    if(tableData.find((item) => item.key===e.target.value)) {
                                        setErrors({key:true,message:'This key already exists!'})
                                    }else {
                                        setErrors({key:false,message:''})
                                        setNewSave(false)
                                    }
                                }else {
                                    setErrors({key:false,message:''})
                                    setNewSave(true)
                                }
                            }
                        }
                    }}
                /> {errors.key && <span>{errors.message}</span>}
            </label><br/>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                />
                Active
            </label>
            <button disabled={newSave} onClick={handleSave}>Save</button>
        </div>
        </div>
    );
}

/**
 * @type {{ selected: number | null, arr: number[] }}
 */
const initialState = {
    token: null
};



/**
 * @param {typeof initialState}
 * I assume this won't actually just be like ADD_ITEM with no payload, but anyway:
 * @param {{ type: "ADD_TOKEN", value: token }}
 * @returns {typeof initialState}
 */
function reducer(state, action) {
    console.log(action)
    switch (action.type) {
        case "ADD_TOKEN":
            // I assume this will not be quite like this:
            return {
                token: action.value
            };
      // I assume there will be other actions...
      // case "REMOVE_ITEM":
      //   return altered state
        default:
            throw new Error();
    }
}
const Id = () => {
    type cvsItem = {
        id: string;
        valid: string;
        date: string;
        delay: string;
    };
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isLoaded, setIsLoaded] = useState(false);
    const [creatingNew, setCreatingNew] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [tableData, setTableData] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const nav = useNavigate();

    const [csvData, setCsvData] = useState([]);
    const [filename, setFilename] = useState("");

    const addToken = async (token) => {
        await dispatch({ type: "ADD_TOKEN", value: token });
    };

    const   columns = [
        {field: "id", headerName: "ID"},
        {
            field: "key",
            headerName: "Key",
            flex: 1,
            cellClassName: "name-column--cell",
            editable: true,
        },
        {
            field: "active",
            headerName: "Active",
            type: "boolean",
            headerAlign: "left",
            align: "left",
        },
        { field: 'act', headerName: 'Change', width: 400, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => clickChange(e, params.row)}
                        variant="contained"
                    >
                        {params.row.active ?
                            <span>Deactivate</span>
                            :
                            <span>Activate</span>
                        }
                    </Button>
                );
            } },
        { field: 'actions', headerName: 'Actions', width: 400, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => clickDelete(e, params.row)}
                        variant="contained"
                    >
                        Delete
                    </Button>
                );
            } }
    ];
    const dataProvider = {
        getList: async (resource, params) => {
            console.log('fetch')
            console.log(`${swServiceUrls}/id`)
            console.log(state);
            const request = new Request(`${swServiceUrls}/id?access=r&resource=sw-service&key=nesto`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("token")}` }),
            });
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                EventBus.dispatch("logout");
                nav("/login");
                throw new Error(response.statusText);
            }
            const data = await response.json();
            console.log(data);
            return data;
        }, // get a list of records based on sort, filter, and pagination
        create: async (resource, params) => {
            const request = new Request(`${swServiceUrls}/id/${resource.key}?valid=${resource.active}&access=w&resource=sw-service&key=nesto`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("token")}` }),
            });
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            // const data = await response.json();
            // console.log(data);
            // return data;
        }, // create a record
        update:  async (resource, params) => {
            console.log(`${swServiceUrls}/id/${resource.key}?valid=${resource.active}&access=w&resource=sw-service&key=nesto`)
            const request = new Request(`${swServiceUrls}/id/${resource.key}?valid=${resource.active}&access=w&resource=sw-service&key=nesto`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("token")}` }),
            });
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            // const data = await response.json();
            // console.log(data);
            // return data;
        }, // update a record based on a patch
        delete: async (resource, params) => {
            const request = new Request(`${swServiceUrls}/id/${resource}&key=nesto`, {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("token")}` }),
            });
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            // const data = await response.json();
            // console.log(data);
            // return data;
        }, // delete a record by id
        upload: async (resource, params) => {
            console.log('--------------------------------------------------------')
            console.log(resource, params)
            const request = new Request(`${swServiceUrls}/id?access=w&resource=sw-service&key=nesto`, {
                method: 'POST',
                body: JSON.stringify(resource),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("token")}` }),
            });
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            // const data = await response.json();
            // console.log(data);
            // return data;
        }, // delete a record by id
    }
    const [clickedRow, setClickedRow] = useState();
    const openPopup = () => {
        // Define a callback function to handle the save action
        setCreatingNew(!creatingNew)
    };
    const onSaveCallback = (inputValue, isChecked) => {
        // Perform actions with input value and checkbox state
        dataProvider.create({ key:inputValue, active: isChecked}).then(()=>{
            const newData = [...tableData, {id:null, key:inputValue, active: isChecked}];
            setTableData(newData)
        })

    };
    const onCloseCallback = (inputValue, isChecked) => {
        openPopup()
    };

    const clickDelete = (e, row) => {
        e.stopPropagation();
        const index = tableData.findIndex(tableRow => tableRow.key === row.key );
        dataProvider.delete(tableData[index].key).then(()=>{
            const newData = [...tableData];
            newData.splice(index,1)
            setTableData(newData)
        })

    };
    const clickChange = (e, row) => {
        e.stopPropagation();
        const index = tableData.findIndex(tableRow => tableRow.key === row.key );
        tableData[index].active = !tableData[index].active;
        dataProvider.update(tableData[index]).then(()=>{
            //tableData[index].active = !tableData[index].active;
            setTableData([...tableData])
        }).catch(()=>{
            tableData[index].active = !tableData[index].active;
        });
    };
    const handleAddRow = () => {
        openPopup()

        // setTableData((tableData) => [...tableData, {key:'',active:false}]);
    };
    const openUploadCSV = (e: any) => {
        console.log('click open')
       inputFile.current.click();
    }
    const inputFile = useRef(null)
    const handleUploadCSV = (e: ChangeEvent<HTMLInputElement>) => {
        console.log('uploadddddddddddddddddddddddd')
            if (!e.target.files) {
                return;
            }
            const file = e.target.files[0];
            const { name } = file;
            setFilename(name);

            const reader = new FileReader();
            reader.onload = (evt) => {
                if (!evt?.target?.result) {
                    return;
                }

                const { result } = evt.target;
                console.log(result);
                const records = parse(result, {
                    columns: ["key", "active", "date","delay"],
                    delimiter: ",",
                    trim: true,
                    skip_empty_lines: true
                });
                setCsvData(records);
                setTableData(records)
                dataProvider.upload(records)
            };
            reader.readAsBinaryString(file);
        // setTableData((tableData) => [...tableData, {key:'',active:false}]);
    };
    const useFetch =  (fetch) => {
        useEffect(() => {

            const fetchData = async function () {
                console.log('get token')
                console.log(localStorage.getItem("token"))
                await addToken(localStorage.getItem("token"))
                console.log(token)
                try {
                    setIsLoaded(false);
                    console.log('load once on mount')
                    const data = await dataProvider.getList();
                    setTableData(data)
                } catch (error) {
                    throw error;
                } finally {
                    setIsLoaded(true)
                }
            };
            fetchData();
        }, [fetch]);
    };

    useFetch(true)



    return (
        <Box m="20px">
            {creatingNew && (
                <Popup onSave={onSaveCallback} onClose={onCloseCallback} tableData={tableData}></Popup>
            )}
            <Header title="IDs" subtitle="Managing the IDs"/>
            <Stack direction="row" spacing={1}>
                {/*<Button size="small" onClick={handleUpdateRow}>*/}
                {/*    Update a row*/}
                {/*</Button>*/}
                {/*<Button size="small" onClick={handleUpdateAllRows}>*/}
                {/*    Update all rows*/}
                {/*</Button>*/}
                {/*<Button size="small" onClick={handleDeleteRow}>*/}
                {/*    Delete a row*/}
                {/*</Button>*/}
                {/*<Button size="small" onClick={handleAddRow} >*/}
                {/*    Add a row*/}
                {/*</Button>AddBoxOutlined*/}
                <IconButton onClick={handleAddRow}>
                    <AddBoxOutlined/>
                </IconButton>
                <DownloadCSV data={tableData} fileName={'keyData'}/>
                <IconButton onClick={openUploadCSV}><UploadOutlined />
                </IconButton>
                <input ref={inputFile} type="file" accept=".csv" hidden onChange={handleUploadCSV}/>
                {/*<Button size="small" onClick={handleUploadCSV}>*/}
                {/*    Upload CSV*/}
                {/*</Button>*/}
            </Stack>
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                {isLoaded && (
                    <DataGrid checkboxSelection rows={tableData} columns={columns}
                              getRowId={(row) =>  row.key + row.active}/>
                )}
            </Box>
        </Box>
    );
};

export default Id;
